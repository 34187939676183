<template>
  <div class="dispatchers">
    <default-user-page v-bind="config"/>
  </div>
</template>

<script>
import {
  getDispatchers,
  getDispatcher,
  postDispatcher, putDispatcher, removeDispatcher,
} from "@/services/dispatchers";
import DefaultUserPage from "@/components/default_user_page";

export default {
  components: {DefaultUserPage},
  data() {
    return {
      config: {
        label: 'dispatcher',
        confirmable: true,
        fetchRecords: getDispatchers,
        fetchRecord: getDispatcher,
        postRecord: postDispatcher,
        putRecord: putDispatcher,
        removeRecord: removeDispatcher,
      }
    }
  }
}
</script>